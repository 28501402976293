<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="400">

      <v-card>

        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">
          //
        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-spacer/>
          <v-btn color="secondary"
                 depressed
                 @click="search">
            <v-icon left>mdi-magnify</v-icon>
            Recherche
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
    data() {
        return {
            isLoading: false,
            dialog: false,
            filter: {},
        }
    },
    methods: {
        open() {
            this.dialog = true
        },
        search() {
            this.dialog = false
            this.$emit('search', this.filter)
        },
    }
}
</script>

<style scoped>

</style>